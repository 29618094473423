// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styles from './Tags.module.scss';

const Tags = ({tags}) => (
  <div className={styles['tags']}>
    <ul className={styles['tags__list']}>
      {tags.map((tag) => (
        <li className={styles['tags__list-item']} key={tag.fieldValue}>
          <Link to={`/tag/${kebabCase(tag.fieldValue)}/`} className={styles['tags__list-item-link']}>
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default Tags;
